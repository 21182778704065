<template>
  <div class="home">
    <el-container>
      <el-aside :style="toggle ? 'width:200px' : 'width:48px'">
        <el-menu router :collapse="!toggle" unique-opened class="el-menu-vertical-demo" background-color="#545c64"
          text-color="#fff" :collapse-transition="false" :default-active="$route.path" active-text-color="#ffd04b">
          <el-menu-item index="/visualization" v-if="isflag">
            <template slot="title">
              <i class="el-icon-house"></i>
              <span class="subTitle" slot="title">数据首页</span>
            </template>
          </el-menu-item>
          <!-- <el-submenu index="9">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>代理公司</span>
            </template>
            <el-menu-item >
              <span slot="title">本公司</span>
            </el-menu-item>
            <el-submenu index="9-1">
              <template slot="title">公司1</template>
              <el-menu-item>项目1</el-menu-item>
              <el-menu-item>项目2</el-menu-item>
            </el-submenu>
            <el-submenu>
              <template slot="title">公司2</template>
              <el-menu-item>项目1</el-menu-item>
              <el-menu-item>项目2</el-menu-item>
              <el-menu-item>项目3</el-menu-item>
            </el-submenu>
          </el-submenu> -->
          <div v-if="isdealer == 'true'">
            <template v-for="item in list_data">
              <template v-if="item.children">
                <el-submenu :key="item.id" :index="item.id" class="chaochu">
                  <template slot="title">
                    <i class="el-icon-office-building"></i>
                    <span class="subTitle" slot="title">{{ item.label }}</span>
                  </template>
                  <el-menu-item :key="item.id" :index="item.id" @click="dianji(item)">
                    <span slot="title">当前公司</span>
                  </el-menu-item>
                  <template v-for="subItem in item.children">
                    <el-submenu v-if="subItem.children" :key="subItem.id" :index="subItem.id" class="chaochu">
                      <template slot="title">
                        <span slot="title" @click="dianji(subItem)">{{ subItem.label }}</span>
                      </template>

                      <el-menu-item v-for="(subItems, indexs) in subItem.children" :key="indexs"
                        @click="dianji(subItems)">{{ subItems.label
                        }}</el-menu-item>
                    </el-submenu>
                    <el-menu-item v-if="!subItem.children" :key="subItem.id" :index="subItem.id" class="chaochu"
                      @click="dianji(subItem)">{{
                        subItem.label
                      }}</el-menu-item>
                  </template>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :key="item.id" :index="item.id">
                  <span class="subTitle" slot="title" @click="dianji(item)">{{ item.label }}</span>
                </el-menu-item>
              </template>
            </template>
          </div>
          <el-menu-item index="/statistics">
            <template slot="title">
              <i class="el-icon-postcard"></i>
              <span class="subTitle" slot="title">归档文件统计</span>
            </template>
          </el-menu-item>
          <el-submenu index="3" v-if="isflag">
            <template slot="title">
              <i class="el-icon-bank-card"></i>
              <span class="subTitle" slot="title">风险管控</span>
            </template>
            <el-menu-item index="/RiskManagement">风险台账</el-menu-item>
            <!-- <el-menu-item index="/RiskControl">表单</el-menu-item> -->
            <el-menu-item index="/Screening">作业活动管控</el-menu-item>
            <el-menu-item index="/Screeningsb">设备设施管控</el-menu-item>
            <el-menu-item index="/SignificantRisk">重大风险统计</el-menu-item>
          </el-submenu>
          <el-menu-item index="/Upload">
            <template slot="title">
              <i class="el-icon-copy-document"></i>
              <span class="subTitle" slot="title">检查任务管理</span>
            </template>
          </el-menu-item>
          <!-- <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-tickets" slot="title"></i>
              <span class="subTitle" slot="title">提交报告</span>
            </template>
            <el-menu-item index="/visualization">
              <template>
                <span>数据首页</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/submitReport">
              <template>
                <span>提交报告</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/Upload">
              <template>
                <span>检查任务管理</span>
              </template>
            </el-menu-item>
          </el-submenu> -->
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="subTitle" slot="title">隐患排查</span>
            </template>

            <el-menu-item index="/Hidden">隐患排查清单

            </el-menu-item>
            <el-menu-item index="/RecordsFebruary">隐患排查记录</el-menu-item>
            <el-menu-item index="/HiddenGovernance">隐患治理台账</el-menu-item>
            <el-menu-item index="/MajorHiddenGovernance">重大隐患治理台账
            </el-menu-item>
          </el-submenu>

          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-collection"></i>
              <span class="subTitle" slot="title">安全生产标准化</span>
            </template>
            <el-menu-item index="/pictureRegulatory">施工阶段照片监管</el-menu-item>
            <el-menu-item index="/evaluationRegulatory">月自评表监管</el-menu-item>

            <el-menu-item index="/projectRegulatory">项目概况监管</el-menu-item>
            <el-menu-item index="/planRegulatory">申报方案监管</el-menu-item>
          </el-submenu>

          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-edit-outline"></i>
              <span class="subTitle" slot="title">双预防评估</span>
            </template>
            <el-menu-item index="/SystemAssessment">系统评估记录</el-menu-item>
            <el-menu-item index="/SummaryAssessment">自评记录汇总</el-menu-item>
          </el-submenu>
          <el-submenu index="6" v-if="rank != '3'">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span class="subTitle" slot="title">系统管理</span>
            </template>
            <el-menu-item index="/enterprise" v-if="flag||isdealer == 'true'">企业管理
            </el-menu-item>
            <!-- <el-menu-item index="/equiment" v-if="flag">设备升级 </el-menu-item> -->
            <el-menu-item index="/a1" v-if="isflag">组织机构管理 </el-menu-item>
            <el-menu-item index="/projectinfo">项目管理 </el-menu-item>
            <el-menu-item index="/userInfo" v-if="rank != '4'">人员账号列表 </el-menu-item>
            <el-menu-item index="/rechargeRecords" v-if="rank == '0'||rank == '1'">充值记录 </el-menu-item>
            <!-- <el-menu-item index="/a2">2222 </el-menu-item> -->
          </el-submenu>
          <el-menu-item index="/additem" v-if="rank == '0' ||isdealer == 'true'">
            <template slot="title">
              <i class="el-icon-files"></i>
              <span class="subTitle" slot="title">工程审核</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header style="height: 50px">
          <div class="btn">
            <el-button v-if="toggle" icon="el-icon-s-fold" class="iconBtn" style="background: #fff; font-size: 16px"
              @click="toggleBtn"></el-button>
            <el-button v-else class="iconBtn" icon="el-icon-s-unfold" style="font-size: 16px; background: #fff"
              @click="toggleBtn"></el-button>
            <!-- <el-button type="primary" @click="goBack">数据首页</el-button> -->
            <!-- <el-breadcrumb class="breadcrumb-container" separator="/">
              <el-breadcrumb-item
                v-for="item in levelList"
                :key="item.path"
                :to="item.path"
              >
                {{ item.title }}
              </el-breadcrumb-item>
            </el-breadcrumb> -->
          </div>
          <div class="name">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link" style="font-size: 20px">
                {{ username
                }}<i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <div class="mainView">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import {
  relationship
} from "../request/risk";
export default {
  name: "Home",
  data() {
    return {
      isdealer: '',
      dialogVisible: false,
      isdealer: '',
      username: "",
      toggle: true,
      flag: false,
      isflag: true,
      rank: '',
      levelList: [
        {
          path: "/visualization",
          title: "首页",
        },
      ],
      list_data: [],

      // levelList:null
    };
  },
  created() {
    this.isdealer = localStorage.getItem('isdealer')
    this.rank = localStorage.getItem("rank")
    this.username = window.localStorage.getItem("username");
    if (window.localStorage.getItem("rank") == 0) {
      this.flag = true;
    }
    if (localStorage.getItem("rank") == 4) {
      this.isflag = false;
    }
    this.getBreadcrumb();
  },
  watch: {
    $route(to, form) {
      // this.getBreadcrumb();
      // console.log(to);
      // console.log(form);
      let str = {};
      str.path = form.path;
      str.title = form.meta.title;
      let a = this.levelList.findIndex((value, index) => {
        return value.path == form.path;
      });
      console.log(a);
      if (a == -1) {
        this.levelList.push(str);
      }
      // console.log('cc');
    },
  },
  mounted() {
    this.isdealer = localStorage.getItem('isdealer')
    if (this.isdealer == 'true') {
      this.relationship()
    }
  },
  methods: {
    // dainji(item){
    //   console.log(item);
    // },
    async relationship() {
      let msg = {
        User_identity: localStorage.getItem("uid")
      }
      const res = await relationship(msg)
      console.log(res, '121111');
      this.list_data = res.data.data
    },
    getBreadcrumb() {
      // only show routes with meta.title
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return (
        name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase()
      );
    },
    goBack() {
      this.$router.push("visualization");
    },
    toggleBtn() {
      this.toggle = !this.toggle;
    },
    handleCommand() {
      // console.log('kk');
      this.$router.push("/Login");
      window.localStorage.clear();
    },
    dianji(item) {
      console.log(item);
      localStorage.setItem('project', item.id)
      localStorage.setItem('type', item.type)
      this.$message({
        showClose: true,
        message: '成功切换到' + item.label,
        type: 'success',
        duration: '5000'
      });
      this.$router.push({
        query: {
          id: item.id,
        }
      })

    },
  },
  // components: {
  //   HelloWorld
  // }
};
</script>

<style>
.home {
  width: 100%;
  height: 100vh !important;
}

.el-container {
  width: 100%;
  height: 100%;
}

.el-header,
.el-footer {
  /* background-color: #b3c0d1; */
  color: #333;

  /* line-height: 40px !important; */
}

.el-aside {
  /* background-color: #d3dce6; */
  color: #333;

  height: 100%;
}

.el-main {
  /* background-color: #e9eef3; */
  color: #333;
  /* text-align: center; */
  line-height: 100%;
}

.el-menu {
  height: 100%;
  border: none;
}

.el-submenu__title {
  /* padding-left: 15px !important; */
}

/* .el-menu i {
  margin-right: 80px !important;
} */
body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.subTitle {
  margin-left: 10px;
}

.iconBtn {
  padding: 10px 15px;
  border: none;

  color: #409eff;
}

.el-button--default i {
  font-size: 25px;
}

.el-header {
  padding: 0 5px;
  display: flex;
  border-bottom: 1px solid #ccc;

}

.btn {
  flex: 1;
}

.name {
  padding: 10px 40px;
  flex: 1;
  font-size: 20px;
  text-align: right;
}

.el-dropdown-link {
  cursor: pointer;
}

.mainView {
  width: 100%;
  height: 100%;
}

/* .foldingIcon {
  width: 60px;
  height: 60px;
} */

.el-submenu__title {
  display: flex;
  align-items: center;
}

.el-submenu__title span {
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  flex: 1;
  padding-right: 20px;
}

.el-menu-item {
  display: flex;
  align-items: center;
  padding-right: 20px !important;
}

.el-menu-item span {
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  flex: 1;
}

/* 动态样式 控制收起时icon图标 */
.packUp .el-submenu__title .svg-icon {
  margin-right: 0% !important;
}
</style>
